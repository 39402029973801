@charset "UTF-8";

.fukahire {
  &_visual {
    margin: 0;
    figcaption {
      display: none;
      font-size: rem(12);
      margin-top: 15px;
      @include mq-max("sm") {
        display: block;
      }
    }
  }
  .m-title {
    display: none;
    @include mq-max("sm") {
      display: block;
    }
  }
  &_description {
    margin: 1.5em 0 2em;
    font-size: rem(20);
    font-weight: bold;
    color: c("red");
    letter-spacing: .1em;
    line-height: 2;
    @include mq-max("sm") {
      font-size: rem(14);
    }
  }
  &_section {
    margin-bottom: 60px;
    letter-spacing: .1em;
    @include mq-max("sm") {
      margin-bottom: 30px;
    }
    p {
      line-height: 2;
      font-size: rem(17);
      @include mq-max("sm") {
        margin-top: 5px;
        font-size: rem(12);
      }
    }
    h3 {
      margin-bottom: 0;
    }
  }
  &_container {
    display: flex;
    @include mq-max("sm") {
      flex-wrap: wrap;
    }
    &._rev {
      @include mq-max("sm") {
        flex-direction: column-reverse;
      }
    }
  }
  &_gallery {
    width: 220px;
    margin: 50px auto 0 0;
    @include mq-max("sm") {
      width: 50%;
      margin: 20px 0 0;
      padding: 0 10px;

    }
    &:last-of-type {
      margin-right: 0;
    }
  }
  &_content {
    flex: 0 0 auto;
    width: 680px;
    margin-right: 40px;
    @include mq-max("sm") {
      width: auto;
      margin: 0;
    }
  }
  &_img {
    margin-top: 60px;
    @include mq-max("sm") {
      margin-top: 0;
    }
  }
}
