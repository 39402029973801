@charset "UTF-8";

.safety {
  h4 {
    font-size: rem(18);
    font-weight: normal;
    color: c("gold");
  }
  &_box {
    padding: 30px 0;
    margin: 1em 0 50px;
    border: 1px solid c("red");
    background-color: #f4c9cc;
    color: c("red");
    font-size: rem(18);
    font-weight: bold;
    text-align: center;
    @include mq-max("sm") {
      padding: 15px;
      br {
        display: none;
      }
    }
  }
  &_table {
    margin-bottom: 60px;
    @include mq-max("sm") {
      overflow-x: auto;
    }
    &::after {
      display: none;
      @include mq-max("sm") {
        display: block;
        content: "横にスクロールしてください。";
        font-size: rem(12);
      }
    }
    table {
      width: 100%;
      border-top: 1px solid c("gray");
      border-left: 1px solid c("gray");
      @include mq-max("sm") {
        font-size: rem(14);
        width: 640px;
      }
      th , td {
        padding: 8px 12px;
        border-right: 1px solid c("gray");
        border-bottom: 1px solid c("gray");
      }
      th {
        font-weight: normal;
        background-color: #eee;
      }
      td {
        width: 120px;
        text-align: center;
        @include mq-max("sm") {
          width: 80px;
        }
      }
    }
    &._width {
      td {
        width: 80px;
        @include mq-max("sm") {
          width: 60px;
        }
      }
    }
  }
}
