@charset "UTF-8";

.c-pagination {
  display: flex;
  justify-content: center;
  .page-numbers {
    display: inline-block;
    padding: 0 6px;
    margin: 0 6px;
    background-color: c("white");
    border: 1px solid c("red");
    border-radius: 2px;
    transition: all .15s;
    &.current {
      background-color: c("red");
      border: 1px solid c("red");
      color: c("white");
      font-weight: bold;
    }
    &:hover {
      text-decoration: none;
      background-color: c("red");
      border: 1px solid c("red");
      color: c("white");
    }
  }

}
