@charset "UTF-8";

.p-nav {
  &_btn {
    display: none;
    @include mq-max("sm") {
      display: block;
      margin-top: 12px;
      font-size: rem(8);
      font-weight: bold;
    }
  }
  &_icon {
    position: relative;
    display: block;
    width: 100%;
    height: 20px;
    margin-bottom: 5px;
    padding: 0;
    span {
      position: absolute;
      display: block;
      width: 100%;
      height: 4px;
      background-color: c("white");
      transform: translateY(-50%);
      transition: all .15s;
      &:first-of-type {
        top: 0;
      }
      &:nth-of-type(2) {
        top: 50%;
      }
      &:last-of-type {
        top: 100%;
      }
    }

  }
  ul {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;
    @include mq-max("sm") {
      //display: none;
      position: absolute;
      flex-direction: column;
      overflow: hidden;
      top: 68px;
      left: 0;
      width: 100%;
      max-height: 0;
      background: c("black");
      z-index: 500;
      transition: all .3s;
    }
    li {
      margin-left: 48px;
      @include mq-max("sm") {
        margin: 0;
        border-top: 1px solid #333;
      }
      a {
        position: relative;
        color: c("white");
        font-weight: bold;
        @include mq-max("sm") {
          display: block;
          padding: 12px;
        }
        &::after {
          position: absolute;
          display: none;
          content: "▶︎";
          top: 50%;
          right: vw(30);
          font-size: rem(10);
          transform: translateY(-50%);
          opacity: .5;
          @include mq-max("sm") {
            display: block;
          }
        }
      }
    }
  }
  &._open {
    ul {
      @include mq-max("sm") {
        max-height: 196px;
      }
    }
    .p-nav_icon {
      span {
        transform: translateY(-50%);
        &:first-of-type {
          top: 50%;
          transform: rotate(-45deg);
        }
        &:nth-of-type(2) {
          opacity: 0;
        }
        &:last-of-type {
          top: 50%;
          transform: rotate(45deg);
        }
      }
    }
  }
}
