@charset "UTF-8";

.restoration {
  padding-left: 60px;
  padding-right: 60px;
  @include mq-max("sm") {
    padding-left: 0;
    padding-right: 0;
  }
  &_description {
    margin: 3em 0;
    font-size: rem(24);
    color: c("gold");
    line-height: 2;
    text-align: center;
    @include mq-max("sm") {
      font-size: rem(18);
      margin: 1.5em 0;
      br {
        display: none;
      }
    }
  }
  &_container {
    display: flex;
    @include mq-max("sm") {
      flex-direction: column;
    }
  }
  &_img {
    flex: 0 0 auto;
    margin-left: 30px;
    @include mq-max("sm") {
      margin: 0;
    }
    img {
      width: 300px;
      @include mq-max("sm") {
        width: 100%;
      }
    }
  }
  &_content {
    flex: 1 0 0;
    p {
      line-height: 2;
    }
  }
}
