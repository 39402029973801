@charset "UTF-8";

.p-header {
  background-color: c("black");
  color: c("white");
  &_container {
    display: flex;
    align-items: center;
    width: 1000px;
    height: 100px;
    margin: 0 auto;
    @include mq-max("sm") {
      width: auto;
      height: vw(160);
      margin: 0 vw(30);
    }
    h1 {
      width: 216px;
      margin-right: auto;
      @include mq-max("sm") {
        width: vw(380);
      }
    }
  }
  &_content {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    aside {
      margin-bottom: 15px;
      font-size: 13px;
      line-height: 1.5;
      @include mq-max("sm") {
        display: none;
      }
    }
  }
}
