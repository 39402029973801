@charset "UTF-8";

.tenpura {
  &_visual {
    margin: 0;
    figcaption {
      display: none;
      font-size: rem(12);
      margin-top: 15px;
      @include mq-max("sm") {
        display: block;
      }
    }
  }
  .m-title {
    display: none;
    @include mq-max("sm") {
      display: block;
    }
  }
  &_description {
    margin: 3em 0;
    font-size: rem(17);
    letter-spacing: .1em;
    line-height: 2;
    @include mq-max("sm") {
      margin: 1.5em 0;
      font-size: rem(14);
      color: c("red");
    }
  }
  &_section {
    margin-bottom: 40px;
    p {
      letter-spacing: .1em;
      font-size: rem(17);
      @include mq-max("sm") {
        margin-top: 0;
        font-size: rem(12);
        line-height: 2;
      }
    }
  }
  &_container {
    display: flex;
    @include mq-max("sm") {
      flex-direction: column;
    }
  }
  &_img {
    flex: 0 0 auto;
    width: 315px;
    margin-right: auto;
    @include mq-max("sm") {
      width: 100%;
      margin-right: 0;
      margin-bottom: 20px;
    }
    &:last-of-type {
      margin-right: 0;
    }
  }
}
