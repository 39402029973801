@charset "UTF-8";

.professional {
  &_description {
    display: flex;
    align-items: center;
    font-size: rem(20);
    color: c("red");
    line-height: 2;
    letter-spacing: .1em;
    @include mq-max("sm") {
      flex-direction: column-reverse;
      font-size: rem(16);
      line-height: 1.5;
    }
    span {
      flex: 0 0 auto;
      width: 215px;
      @include mq-max("sm") {
        width: vw(215);
      }
    }
  }
  &_section {
    margin-bottom: 50px;
    letter-spacing: .1em;
    @include mq-max("sm") {
      margin-bottom: 30px;
    }
    h3 {
      padding-left: 1em;
      font-size: rem(25);
      font-weight: normal;
      border-left: 10px solid c("red");
      background: url("../images/professional/bg.png") left center repeat;
      background-size: auto 100%;
      color: c("white");
      line-height: 2;
      @include mq-max("sm") {
        font-size: rem(20);
      }
    }
  }
  &_container {
    display: flex;
    @include mq-max("sm") {
      flex-direction: column-reverse;
    }
    p {
      font-size: rem(17);
      line-height: 2;
      @include mq-max("sm") {
        font-size: rem(14);
      }
    }
    a {
      color: c("red");
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
  &_img {
    flex: 0 0 auto;
    width: 348px;
    margin-left: 30px;
    @include mq-max("sm") {
      width: 100%;
      margin: 0;
    }
  }
}
