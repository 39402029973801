@charset "UTF-8";

.c-pagenav {
  display: flex;
  padding: 30px 4em;
  &_item {
    a {
      display: block;
      padding: 0 6px;
      margin: 0 6px;
      background-color: c("white");
      border: 1px solid c("red");
      border-radius: 2px;
      transition: all .15s;
      &:hover {
        text-decoration: none;
        background-color: c("red");
        border: 1px solid c("red");
        color: c("white");
      }
    }
    &._prev {
      margin-right: auto;
    }
    &._next {
      margin-left: auto;
    }
  }
}
