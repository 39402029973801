@charset "UTF-8";
/*! sanitize.css v5.0.0 | CC0 License | github.com/jonathantneal/sanitize.css */
/* Document (https://html.spec.whatwg.org/multipage/semantics.html#semantics)
   ========================================================================== */
/**
 * 1. Remove repeating backgrounds in all browsers (opinionated).
 * 2. Add box sizing inheritence in all browsers (opinionated).
 */
*,
::before,
::after {
  background-repeat: no-repeat;
  /* 1 */
  box-sizing: inherit;
  /* 2 */
}

/**
 * 1. Add text decoration inheritance in all browsers (opinionated).
 * 2. Add vertical alignment inheritence in all browsers (opinionated).
 */
::before,
::after {
  text-decoration: inherit;
  /* 1 */
  vertical-align: inherit;
  /* 2 */
}

/**
 * 1. Add border box sizing in all browsers (opinionated).
 * 2. Add the default cursor in all browsers (opinionated).
 * 3. Prevent font size adjustments after orientation changes in IE and iOS.
 */
html {
  box-sizing: border-box;
  /* 1 */
  cursor: default;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 3 */
  -webkit-text-size-adjust: 100%;
  /* 3 */
}

/* Sections (https://html.spec.whatwg.org/multipage/semantics.html#sections)
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 */
article,
aside,
footer,
header,
nav,
section {
  display: block;
}

/**
 * Remove the margin in all browsers (opinionated).
 */
body {
  margin: 0;
}

/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Firefox, and Safari.
 */
h1 {
  font-size: 2em;
  margin: .67em 0;
}

/* Grouping content (https://html.spec.whatwg.org/multipage/semantics.html#grouping-content)
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 * 1. Add the correct display in IE.
 */
figcaption,
figure,
main {
  /* 1 */
  display: block;
}

/**
 * Add the correct margin in IE 8.
 */
figure {
  margin: 1em 40px;
}

/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */
}

/**
 * Remove the list style on navigation lists in all browsers (opinionated).
 */
nav ol,
nav ul {
  list-style: none;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/* Text-level semantics (https://html.spec.whatwg.org/multipage/semantics.html#text-level-semantics)
   ========================================================================== */
/**
 * 1. Remove the gray background on active links in IE 10.
 * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
 */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */
}

/**
 * 1. Remove the bottom border in Firefox 39-.
 * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
 */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */
}

/**
 * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
 */
b,
strong {
  font-weight: inherit;
}

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/**
 * Add the correct font style in Android 4.3-.
 */
dfn {
  font-style: italic;
}

/**
 * Add the correct background and color in IE 9-.
 */
mark {
  background-color: #ffff00;
  color: #000000;
}

/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%;
}

/**
 * Prevent `sub` and `sup` elements from affecting the line height in
 * all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

/*
 * Remove the text shadow on text selections (opinionated).
 * 1. Restore the coloring undone by defining the text shadow (opinionated).
 */
::-moz-selection {
  background-color: #b3d4fc;
  /* 1 */
  color: #000000;
  /* 1 */
  text-shadow: none;
}

::selection {
  background-color: #b3d4fc;
  /* 1 */
  color: #000000;
  /* 1 */
  text-shadow: none;
}

/* Embedded content (https://html.spec.whatwg.org/multipage/embedded-content.html#embedded-content)
   ========================================================================== */
/*
 * Change the alignment on media elements in all browers (opinionated).
 */
audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

/**
 * Add the correct display in IE 9-.
 */
audio,
video {
  display: inline-block;
}

/**
 * Add the correct display in iOS 4-7.
 */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
 * Remove the border on images inside links in IE 10-.
 */
img {
  border-style: none;
}

/**
 * Change the fill color to match the text color in all browsers (opinionated).
 */
svg {
  fill: currentColor;
}

/**
 * Hide the overflow in IE.
 */
svg:not(:root) {
  overflow: hidden;
}

/* Tabular data (https://html.spec.whatwg.org/multipage/tables.html#tables)
   ========================================================================== */
/**
 * Collapse border spacing
 */
table {
  border-collapse: collapse;
}

/* Forms (https://html.spec.whatwg.org/multipage/forms.html#forms)
   ========================================================================== */
/**
 * Remove the margin in Firefox and Safari.
 */
button,
input,
optgroup,
select,
textarea {
  margin: 0;
}

/**
 * Inherit styling in all browsers (opinionated).
 */
button,
input,
select,
textarea {
  background-color: transparent;
  color: inherit;
  font-size: inherit;
  line-height: inherit;
}

/**
 * Show the overflow in IE.
 * 1. Show the overflow in Edge.
 */
button,
input {
  /* 1 */
  overflow: visible;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */
button,
select {
  /* 1 */
  text-transform: none;
}

/**
 * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
 *    controls in Android 4.
 * 2. Correct the inability to style clickable types in iOS and Safari.
 */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
}

/**
 * Remove the inner border and padding in Firefox.
 */
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
 * Restore the focus styles unset by the previous rule.
 */
button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Remove the padding so developers are not caught out when they zero out
 *    `fieldset` elements in all browsers.
 */
legend {
  box-sizing: border-box;
  /* 1 */
  color: inherit;
  /* 2 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  white-space: normal;
  /* 1 */
}

/**
 * 1. Add the correct display in IE 9-.
 * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */
}

/**
 * 1. Remove the default vertical scrollbar in IE.
 * 2. Change the resize direction on textareas in all browsers (opinionated).
 */
textarea {
  overflow: auto;
  /* 1 */
  resize: vertical;
  /* 2 */
}

/**
 * 1. Add the correct box sizing in IE 10-.
 * 2. Remove the padding in IE 10-.
 */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}

/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
}

/**
 * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
 */
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

/* Interactive elements (https://html.spec.whatwg.org/multipage/forms.html#interactive-elements)
   ========================================================================== */
/*
 * Add the correct display in IE 9-.
 * 1. Add the correct display in Edge, IE, and Firefox.
 */
details,
menu {
  display: block;
}

/*
 * Add the correct display in all browsers.
 */
summary {
  display: list-item;
}

/* Scripting (https://html.spec.whatwg.org/multipage/scripting.html#scripting-3)
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 */
canvas {
  display: inline-block;
}

/**
 * Add the correct display in IE.
 */
template {
  display: none;
}

/* User interaction (https://html.spec.whatwg.org/multipage/interaction.html#editing)
   ========================================================================== */
/*
 * Remove the tapping delay on clickable elements (opinionated).
 * 1. Remove the tapping delay in IE 10.
 */
a,
area,
button,
input,
label,
select,
summary,
textarea,
[tabindex] {
  -ms-touch-action: manipulation;
  /* 1 */
  touch-action: manipulation;
}

/**
 * Add the correct display in IE 10-.
 */
[hidden] {
  display: none;
}

/* ARIA (https://w3c.github.io/html-aria/)
   ========================================================================== */
/**
 * Change the cursor on busy elements (opinionated).
 */
[aria-busy="true"] {
  cursor: progress;
}

/*
 * Change the cursor on control elements (opinionated).
 */
[aria-controls] {
  cursor: pointer;
}

/*
 * Change the display on visually hidden accessible elements (opinionated).
 */
[aria-hidden="false"][hidden]:not(:focus) {
  clip: rect(0, 0, 0, 0);
  display: inherit;
  position: absolute;
}

/*
 * Change the cursor on disabled, not-editable, or otherwise
 * inoperable elements (opinionated).
 */
[aria-disabled] {
  cursor: default;
}

:root {
  font-size: 16px;
}

body {
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;
  line-height: 1.5;
  color: #333;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}

figure {
  margin-left: 0;
  margin-right: 0;
}

figcaption:not(:first-child) {
  margin-top: .5em;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
  backface-visibility: hidden;
  -ms-backface-visibility: visible;
}

a {
  color: #c1272d;
  text-decoration: none;
}

a img {
  transition: opacity 0.15s;
}

a:hover {
  color: #c1272d;
  text-decoration: underline;
}

a:hover img {
  opacity: 0.75;
}

a:visited {
  color: #c1272d;
}

input, textarea, select, button {
  font-family: inherit;
}

.l-banner {
  margin: 60px 0;
}

@media (max-width: 767px) {
  .l-banner {
    margin: 30px 0;
  }
}

.l-container {
  width: 1000px;
  margin: 0 auto;
}

@media (max-width: 767px) {
  .l-container {
    width: auto;
    margin: 0 4vw;
  }
}

.m-contact {
  display: flex;
  align-items: center;
  padding: 10px 0;
  background-color: #c1272d;
}

@media (max-width: 767px) {
  .m-contact {
    flex-direction: column;
    padding: 12px;
  }
}

.m-contact_title {
  width: 214px;
  margin-left: 15px;
}

@media (max-width: 767px) {
  .m-contact_title {
    display: none;
  }
}

.m-contact_info {
  margin-left: 15px;
  color: #fff;
  letter-spacing: .1em;
}

@media (max-width: 767px) {
  .m-contact_info {
    text-align: center;
    margin: 0;
  }
}

.m-contact_info h4 {
  margin: 0;
  font-size: 1.75rem;
  line-height: 1;
}

@media (max-width: 767px) {
  .m-contact_info h4 {
    font-size: 0.875rem;
  }
}

.m-contact_address {
  margin: 10px 0 0;
  font-size: 0.9375rem;
}

@media (max-width: 767px) {
  .m-contact_address {
    font-size: 0.5625rem;
    margin-top: 5px;
  }
}

.m-contact_tel {
  margin: 0;
  font-family: "Century Gothic", sans-serif;
  font-size: 1.75rem;
}

@media (max-width: 767px) {
  .m-contact_tel {
    font-size: 0.875rem;
    margin-bottom: 5px;
  }
}

.m-contact_btn {
  margin-left: auto;
  margin-right: 35px;
}

@media (max-width: 767px) {
  .m-contact_btn {
    margin: 0;
  }
}

.m-contact_btn a {
  display: block;
  width: 380px;
  height: 36px;
  background-color: #fff;
  border-radius: 18px;
  line-height: 36px;
  color: #ed1c24;
  text-align: center;
  font-weight: bold;
  font-size: 1.3125rem;
  letter-spacing: .2em;
}

@media (max-width: 767px) {
  .m-contact_btn a {
    width: 50.66667vw;
    font-size: 0.625rem;
    line-height: 18px;
    height: 18px;
    border-radius: 9px;
  }
}

.m-heading {
  margin-top: 0;
  padding: 0 20px;
  background-color: #000;
  color: #cc9600;
  font-size: 1.375rem;
  font-weight: normal;
  line-height: 50px;
}

@media (max-width: 767px) {
  .m-heading {
    font-size: 1.0625rem;
    margin-bottom: 10px;
    line-height: 35px;
  }
}

.m-heading::before {
  display: inline-block;
  content: "";
  width: 45px;
  height: 42px;
  margin-right: 8px;
  background: url("../images/icon-mark.png") center no-repeat;
  background-size: contain;
  vertical-align: middle;
}

@media (max-width: 767px) {
  .m-heading::before {
    width: 25px;
    height: 22px;
    margin-right: 5px;
  }
}

.m-heading._white {
  padding: 0;
  background: none;
  color: #cc9600;
  font-size: 1.8125rem;
}

@media (max-width: 767px) {
  .m-heading._white {
    font-size: 1.5625rem;
  }
}

.m-heading._no-mark::before {
  display: none;
}

.m-heading-sub {
  font-size: 1.625rem;
  font-weight: normal;
  color: #c1272d;
  margin-bottom: .5em;
}

@media (max-width: 767px) {
  .m-heading-sub {
    font-size: 1rem;
  }
}

.m-table table {
  width: 100%;
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
}

@media (max-width: 767px) {
  .m-table table {
    font-size: 0.875rem;
  }
}

@media (max-width: 767px) {
  .m-table table tr {
    display: block;
  }
}

.m-table table th, .m-table table td {
  padding: 8px 12px;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}

@media (max-width: 767px) {
  .m-table table th, .m-table table td {
    display: block;
  }
}

.m-table table th {
  font-weight: normal;
  background-color: #eee;
}

.m-title {
  margin-top: 0;
  margin-bottom: 1.5em;
  position: relative;
  text-align: center;
  color: #cc9600;
  font-size: 2.25rem;
  font-weight: normal;
}

@media (max-width: 767px) {
  .m-title {
    font-size: 1.875rem;
  }
}

.m-title::after {
  position: absolute;
  content: "";
  display: block;
  bottom: -10px;
  left: 50%;
  width: 42px;
  height: 8px;
  background-color: #cc9600;
  opacity: .3;
  transform: translateX(-50%);
}

.c-pagenav {
  display: flex;
  padding: 30px 4em;
}

.c-pagenav_item a {
  display: block;
  padding: 0 6px;
  margin: 0 6px;
  background-color: #fff;
  border: 1px solid #c1272d;
  border-radius: 2px;
  transition: all .15s;
}

.c-pagenav_item a:hover {
  text-decoration: none;
  background-color: #c1272d;
  border: 1px solid #c1272d;
  color: #fff;
}

.c-pagenav_item._prev {
  margin-right: auto;
}

.c-pagenav_item._next {
  margin-left: auto;
}

.c-pagination {
  display: flex;
  justify-content: center;
}

.c-pagination .page-numbers {
  display: inline-block;
  padding: 0 6px;
  margin: 0 6px;
  background-color: #fff;
  border: 1px solid #c1272d;
  border-radius: 2px;
  transition: all .15s;
}

.c-pagination .page-numbers.current {
  background-color: #c1272d;
  border: 1px solid #c1272d;
  color: #fff;
  font-weight: bold;
}

.c-pagination .page-numbers:hover {
  text-decoration: none;
  background-color: #c1272d;
  border: 1px solid #c1272d;
  color: #fff;
}

.c-section {
  margin-bottom: 60px;
}

.p-footer {
  position: relative;
  padding: 30px 0 20px;
  margin-top: 80px;
  background-color: #000;
  color: #fff;
}

@media (max-width: 767px) {
  .p-footer {
    margin-top: 50px;
    padding: 15px 0;
  }
}

.p-footer_container {
  display: flex;
  width: 1000px;
  margin: 0 auto 60px;
}

@media (max-width: 767px) {
  .p-footer_container {
    flex-wrap: wrap;
    width: auto;
    margin: 0 4vw 30px;
  }
}

.p-footer_information {
  margin-right: auto;
}

.p-footer_information h3 {
  margin: 0 0 20px;
  width: 134px;
}

@media (max-width: 767px) {
  .p-footer_information h3 {
    width: 28.53333vw;
    margin-bottom: 10px;
  }
}

.p-footer_information p {
  font-size: 0.875rem;
}

@media (max-width: 767px) {
  .p-footer_information p {
    font-size: 0.625rem;
  }
}

.p-footer_nav ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

@media (max-width: 767px) {
  .p-footer_nav ul {
    display: none;
  }
}

.p-footer_nav ul li {
  margin-left: 24px;
}

.p-footer_nav ul li a {
  color: #fff;
  font-size: 0.875rem;
  font-weight: bold;
}

.p-footer_nav ul li a span {
  color: #c1272d;
}

.p-footer_copy {
  text-align: center;
  color: #666;
  font-size: 0.875rem;
}

@media (max-width: 767px) {
  .p-footer_copy {
    font-size: 0.4375rem;
    color: #fff;
  }
}

.p-footer_btn {
  display: block;
  width: 109px;
  margin-top: 25px;
  margin-left: auto;
}

@media (max-width: 767px) {
  .p-footer_btn {
    position: absolute;
    width: 19.33333vw;
    top: -9.33333vw;
    right: 2.66667vw;
    margin: 0;
  }
}

.p-header {
  background-color: #000;
  color: #fff;
}

.p-header_container {
  display: flex;
  align-items: center;
  width: 1000px;
  height: 100px;
  margin: 0 auto;
}

@media (max-width: 767px) {
  .p-header_container {
    width: auto;
    height: 21.33333vw;
    margin: 0 4vw;
  }
}

.p-header_container h1 {
  width: 216px;
  margin-right: auto;
}

@media (max-width: 767px) {
  .p-header_container h1 {
    width: 50.66667vw;
  }
}

.p-header_content {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.p-header_content aside {
  margin-bottom: 15px;
  font-size: 13px;
  line-height: 1.5;
}

@media (max-width: 767px) {
  .p-header_content aside {
    display: none;
  }
}

.p-main {
  padding-top: 60px;
}

@media (max-width: 767px) {
  .p-main {
    padding-top: 40px;
  }
}

.p-main._is-top {
  padding-top: 0;
}

.p-nav_btn {
  display: none;
}

@media (max-width: 767px) {
  .p-nav_btn {
    display: block;
    margin-top: 12px;
    font-size: 0.5rem;
    font-weight: bold;
  }
}

.p-nav_icon {
  position: relative;
  display: block;
  width: 100%;
  height: 20px;
  margin-bottom: 5px;
  padding: 0;
}

.p-nav_icon span {
  position: absolute;
  display: block;
  width: 100%;
  height: 4px;
  background-color: #fff;
  transform: translateY(-50%);
  transition: all .15s;
}

.p-nav_icon span:first-of-type {
  top: 0;
}

.p-nav_icon span:nth-of-type(2) {
  top: 50%;
}

.p-nav_icon span:last-of-type {
  top: 100%;
}

.p-nav ul {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
}

@media (max-width: 767px) {
  .p-nav ul {
    position: absolute;
    flex-direction: column;
    overflow: hidden;
    top: 68px;
    left: 0;
    width: 100%;
    max-height: 0;
    background: #000;
    z-index: 500;
    transition: all .3s;
  }
}

.p-nav ul li {
  margin-left: 48px;
}

@media (max-width: 767px) {
  .p-nav ul li {
    margin: 0;
    border-top: 1px solid #333;
  }
}

.p-nav ul li a {
  position: relative;
  color: #fff;
  font-weight: bold;
}

@media (max-width: 767px) {
  .p-nav ul li a {
    display: block;
    padding: 12px;
  }
}

.p-nav ul li a::after {
  position: absolute;
  display: none;
  content: "▶︎";
  top: 50%;
  right: 4vw;
  font-size: 0.625rem;
  transform: translateY(-50%);
  opacity: .5;
}

@media (max-width: 767px) {
  .p-nav ul li a::after {
    display: block;
  }
}

@media (max-width: 767px) {
  .p-nav._open ul {
    max-height: 196px;
  }
}

.p-nav._open .p-nav_icon span {
  transform: translateY(-50%);
}

.p-nav._open .p-nav_icon span:first-of-type {
  top: 50%;
  transform: rotate(-45deg);
}

.p-nav._open .p-nav_icon span:nth-of-type(2) {
  opacity: 0;
}

.p-nav._open .p-nav_icon span:last-of-type {
  top: 50%;
  transform: rotate(45deg);
}

.about_container {
  display: flex;
  margin-bottom: 40px;
}

@media (max-width: 767px) {
  .about_container {
    flex-direction: column;
  }
}

.about_img {
  flex: 0 0 auto;
  width: 300px;
  margin-right: 30px;
}

@media (max-width: 767px) {
  .about_img {
    width: 100%;
    margin: 0 0 20px 0;
  }
}

.about_content h4 {
  font-size: 1.25rem;
  margin-top: 0;
  color: #cc9600;
  font-weight: normal;
}

@media (max-width: 767px) {
  .about_content h4 {
    font-size: 1.125rem;
  }
}

.about_content p {
  font-size: 0.875rem;
}

.about_map iframe {
  width: 100%;
}

@media (max-width: 767px) {
  .about_map iframe {
    height: 280px;
  }
}

.about .m-heading {
  margin-bottom: 40px;
}

@media (max-width: 767px) {
  .about .m-heading {
    margin-bottom: 20px;
  }
}

.p-blog_body {
  border-top: 1px solid #ccc;
}

.p-blog_article {
  display: flex;
  padding-bottom: 20px;
  margin: 20px;
  border-bottom: 1px solid #ccc;
}

@media (max-width: 767px) {
  .p-blog_article {
    flex-direction: column;
    margin: 20px 0;
  }
}

.p-blog_contents {
  flex: 1 1 auto;
}

.p-blog_content p {
  word-break: break-all;
}

.p-blog_thumb {
  width: 360px;
  flex: 0 0 auto;
  margin-left: 30px;
}

@media (max-width: 767px) {
  .p-blog_thumb {
    width: 100%;
    margin: 10px 0 0;
  }
}

.p-blog_title {
  margin: 0;
  color: #cc9600;
  font-size: 1.5rem;
  font-weight: normal;
}

.p-blog_more {
  display: block;
  width: 100px;
  margin-left: auto;
  font-size: 0.875rem;
}

.p-blog_category {
  flex: 0 0 auto;
  display: flex;
  flex-wrap: wrap;
  margin: 12px 0 20px;
  padding: 0;
}

.p-blog_category._is-archive {
  margin: 12px 0 0;
}

.p-blog_category a {
  margin-right: 5px;
  display: block;
  background-color: #999;
  padding: .1em .5em;
  border-radius: 4px;
  color: #fff;
  font-size: 0.75rem;
  font-weight: bold;
  transition: all .15s;
}

.p-blog_category a:hover {
  text-decoration: none;
  background-color: #c1272d;
}

.fukahire_visual {
  margin: 0;
}

.fukahire_visual figcaption {
  display: none;
  font-size: 0.75rem;
  margin-top: 15px;
}

@media (max-width: 767px) {
  .fukahire_visual figcaption {
    display: block;
  }
}

.fukahire .m-title {
  display: none;
}

@media (max-width: 767px) {
  .fukahire .m-title {
    display: block;
  }
}

.fukahire_description {
  margin: 1.5em 0 2em;
  font-size: 1.25rem;
  font-weight: bold;
  color: #c1272d;
  letter-spacing: .1em;
  line-height: 2;
}

@media (max-width: 767px) {
  .fukahire_description {
    font-size: 0.875rem;
  }
}

.fukahire_section {
  margin-bottom: 60px;
  letter-spacing: .1em;
}

@media (max-width: 767px) {
  .fukahire_section {
    margin-bottom: 30px;
  }
}

.fukahire_section p {
  line-height: 2;
  font-size: 1.0625rem;
}

@media (max-width: 767px) {
  .fukahire_section p {
    margin-top: 5px;
    font-size: 0.75rem;
  }
}

.fukahire_section h3 {
  margin-bottom: 0;
}

.fukahire_container {
  display: flex;
}

@media (max-width: 767px) {
  .fukahire_container {
    flex-wrap: wrap;
  }
}

@media (max-width: 767px) {
  .fukahire_container._rev {
    flex-direction: column-reverse;
  }
}

.fukahire_gallery {
  width: 220px;
  margin: 50px auto 0 0;
}

@media (max-width: 767px) {
  .fukahire_gallery {
    width: 50%;
    margin: 20px 0 0;
    padding: 0 10px;
  }
}

.fukahire_gallery:last-of-type {
  margin-right: 0;
}

.fukahire_content {
  flex: 0 0 auto;
  width: 680px;
  margin-right: 40px;
}

@media (max-width: 767px) {
  .fukahire_content {
    width: auto;
    margin: 0;
  }
}

.fukahire_img {
  margin-top: 60px;
}

@media (max-width: 767px) {
  .fukahire_img {
    margin-top: 0;
  }
}

.news {
  width: 800px;
}

@media (max-width: 767px) {
  .news {
    width: auto;
  }
}

.news_container {
  margin-bottom: 60px;
  border-top: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-left: 1px solid #ccc;
}

@media (max-width: 767px) {
  .news_container {
    margin-bottom: 30px;
  }
}

.news_article {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #ccc;
  padding: .5em 0;
}

@media (max-width: 767px) {
  .news_article {
    flex-direction: column;
  }
}

.news_date {
  flex: 0 0 auto;
  width: 120px;
  font-size: 1rem;
  color: #cc9600;
  padding: 0 1em;
}

.news_title {
  margin: 0;
  font-weight: normal;
  font-size: 1rem;
  padding: 0 1em;
}

.news_title a {
  color: #000;
}

.news_title a:hover {
  color: #c1272d;
}

.news_category {
  flex: 0 0 auto;
  margin: 0 0 20px;
  max-width: 100%;
}

.news_category._is-archive {
  margin: 0 0 0 20px;
}

.news_category ul {
  display: flex;
  flex-wrap: wrap;
  margin: 2px 0 0;
  padding: 0;
  list-style: none;
}

.news_category ul li {
  margin-right: 5px;
}

.news_category ul li a {
  display: block;
  background-color: #999;
  padding: .1em .5em;
  border-radius: 4px;
  color: #fff;
  font-size: 0.75rem;
  font-weight: bold;
  transition: all .15s;
}

.news_category ul li a:hover {
  text-decoration: none;
  background-color: #c1272d;
}

.post {
  padding-left: 50px;
  padding-right: 50px;
}

@media (max-width: 767px) {
  .post {
    padding-left: 0;
    padding-right: 0;
  }
}

.post_thumb {
  text-align: center;
}

.post_contents {
  word-break: break-all;
}

.post_contents h1 {
  margin-top: 0;
  padding: 0 20px;
  background-color: #000;
  color: #cc9600;
  font-size: 1.375rem;
  font-weight: normal;
  line-height: 50px;
}

@media (max-width: 767px) {
  .post_contents h1 {
    font-size: 1.0625rem;
    margin-bottom: 10px;
    line-height: 35px;
  }
}

.post_contents h2 {
  color: #c1272d;
  font-weight: normal;
}

.post_contents p {
  line-height: 2;
}

.post_contents form {
  padding: 0;
  margin: 60px 0;
}

@media (max-width: 767px) {
  .post_contents form {
    margin: 30px 0;
  }
}

.post_contents form label {
  display: flex;
}

@media (max-width: 767px) {
  .post_contents form label {
    flex-direction: column;
  }
}

.post_contents form label ._label {
  width: 180px;
  padding: .5em 1em .5em 0;
  text-align: right;
}

@media (max-width: 767px) {
  .post_contents form label ._label {
    display: block;
    width: 100%;
    text-align: left;
  }
}

.post_contents form label ._input {
  flex: 1 0 auto;
}

.post_contents form label ._input input, .post_contents form label ._input textarea {
  width: 100%;
}

.post_contents form label input, .post_contents form label textarea {
  background-color: #f2f2f2;
  border: 0;
  padding: .5em;
}

.post_contents form ._footer {
  display: flex;
  flex-wrap: wrap;
  margin-left: 180px;
}

@media (max-width: 767px) {
  .post_contents form ._footer {
    margin-left: 0;
  }
}

.post_contents form ._footer .g-recaptcha {
  width: 100%;
}

.post_contents form ._footer ._button {
  width: 100%;
  padding: .5em;
  background-color: #000;
  color: #fff;
  font-size: 1.25rem;
}

.post_contents form ._footer ._back {
  width: 45%;
  padding: .5em;
  margin: 0 auto;
  background-color: #999;
  color: #fff;
  font-size: 1.25rem;
}

.post_contents form ._footer ._submit {
  width: 45%;
  margin: 0 auto;
  padding: .5em;
  background-color: #000;
  color: #fff;
  font-size: 1.25rem;
}

.post_contents table {
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
}

.post_contents table th, .post_contents table td {
  padding: .2em .5em;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}

.post_contents table thead th {
  background-color: #eee;
}

.post_contents img {
  max-width: 100%;
}

.post_contents div {
  max-width: 100%;
}

.post_contents blockquote {
  padding: 1em;
  background-color: #eee;
  border: 1px solid #ccc;
}

@media (max-width: 767px) {
  .post_contents blockquote {
    margin: 1em;
  }
}

.post_contents blockquote cite {
  display: block;
  font-weight: bold;
  color: #888;
  text-align: right;
}

.post_contents a {
  text-decoration: underline;
}

.post_contents a:hover {
  text-decoration: none;
}

.post_contents p, .post_contents pre {
  word-break: break-all;
  overflow-x: auto;
}

.professional_description {
  display: flex;
  align-items: center;
  font-size: 1.25rem;
  color: #c1272d;
  line-height: 2;
  letter-spacing: .1em;
}

@media (max-width: 767px) {
  .professional_description {
    flex-direction: column-reverse;
    font-size: 1rem;
    line-height: 1.5;
  }
}

.professional_description span {
  flex: 0 0 auto;
  width: 215px;
}

@media (max-width: 767px) {
  .professional_description span {
    width: 28.66667vw;
  }
}

.professional_section {
  margin-bottom: 50px;
  letter-spacing: .1em;
}

@media (max-width: 767px) {
  .professional_section {
    margin-bottom: 30px;
  }
}

.professional_section h3 {
  padding-left: 1em;
  font-size: 1.5625rem;
  font-weight: normal;
  border-left: 10px solid #c1272d;
  background: url("../images/professional/bg.png") left center repeat;
  background-size: auto 100%;
  color: #fff;
  line-height: 2;
}

@media (max-width: 767px) {
  .professional_section h3 {
    font-size: 1.25rem;
  }
}

.professional_container {
  display: flex;
}

@media (max-width: 767px) {
  .professional_container {
    flex-direction: column-reverse;
  }
}

.professional_container p {
  font-size: 1.0625rem;
  line-height: 2;
}

@media (max-width: 767px) {
  .professional_container p {
    font-size: 0.875rem;
  }
}

.professional_container a {
  color: #c1272d;
  text-decoration: underline;
}

.professional_container a:hover {
  text-decoration: none;
}

.professional_img {
  flex: 0 0 auto;
  width: 348px;
  margin-left: 30px;
}

@media (max-width: 767px) {
  .professional_img {
    width: 100%;
    margin: 0;
  }
}

.restoration {
  padding-left: 60px;
  padding-right: 60px;
}

@media (max-width: 767px) {
  .restoration {
    padding-left: 0;
    padding-right: 0;
  }
}

.restoration_description {
  margin: 3em 0;
  font-size: 1.5rem;
  color: #cc9600;
  line-height: 2;
  text-align: center;
}

@media (max-width: 767px) {
  .restoration_description {
    font-size: 1.125rem;
    margin: 1.5em 0;
  }
  .restoration_description br {
    display: none;
  }
}

.restoration_container {
  display: flex;
}

@media (max-width: 767px) {
  .restoration_container {
    flex-direction: column;
  }
}

.restoration_img {
  flex: 0 0 auto;
  margin-left: 30px;
}

@media (max-width: 767px) {
  .restoration_img {
    margin: 0;
  }
}

.restoration_img img {
  width: 300px;
}

@media (max-width: 767px) {
  .restoration_img img {
    width: 100%;
  }
}

.restoration_content {
  flex: 1 0 0;
}

.restoration_content p {
  line-height: 2;
}

.safety h4 {
  font-size: 1.125rem;
  font-weight: normal;
  color: #cc9600;
}

.safety_box {
  padding: 30px 0;
  margin: 1em 0 50px;
  border: 1px solid #c1272d;
  background-color: #f4c9cc;
  color: #c1272d;
  font-size: 1.125rem;
  font-weight: bold;
  text-align: center;
}

@media (max-width: 767px) {
  .safety_box {
    padding: 15px;
  }
  .safety_box br {
    display: none;
  }
}

.safety_table {
  margin-bottom: 60px;
}

@media (max-width: 767px) {
  .safety_table {
    overflow-x: auto;
  }
}

.safety_table::after {
  display: none;
}

@media (max-width: 767px) {
  .safety_table::after {
    display: block;
    content: "横にスクロールしてください。";
    font-size: 0.75rem;
  }
}

.safety_table table {
  width: 100%;
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
}

@media (max-width: 767px) {
  .safety_table table {
    font-size: 0.875rem;
    width: 640px;
  }
}

.safety_table table th, .safety_table table td {
  padding: 8px 12px;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}

.safety_table table th {
  font-weight: normal;
  background-color: #eee;
}

.safety_table table td {
  width: 120px;
  text-align: center;
}

@media (max-width: 767px) {
  .safety_table table td {
    width: 80px;
  }
}

.safety_table._width td {
  width: 80px;
}

@media (max-width: 767px) {
  .safety_table._width td {
    width: 60px;
  }
}

.tenpura_visual {
  margin: 0;
}

.tenpura_visual figcaption {
  display: none;
  font-size: 0.75rem;
  margin-top: 15px;
}

@media (max-width: 767px) {
  .tenpura_visual figcaption {
    display: block;
  }
}

.tenpura .m-title {
  display: none;
}

@media (max-width: 767px) {
  .tenpura .m-title {
    display: block;
  }
}

.tenpura_description {
  margin: 3em 0;
  font-size: 1.0625rem;
  letter-spacing: .1em;
  line-height: 2;
}

@media (max-width: 767px) {
  .tenpura_description {
    margin: 1.5em 0;
    font-size: 0.875rem;
    color: #c1272d;
  }
}

.tenpura_section {
  margin-bottom: 40px;
}

.tenpura_section p {
  letter-spacing: .1em;
  font-size: 1.0625rem;
}

@media (max-width: 767px) {
  .tenpura_section p {
    margin-top: 0;
    font-size: 0.75rem;
    line-height: 2;
  }
}

.tenpura_container {
  display: flex;
}

@media (max-width: 767px) {
  .tenpura_container {
    flex-direction: column;
  }
}

.tenpura_img {
  flex: 0 0 auto;
  width: 315px;
  margin-right: auto;
}

@media (max-width: 767px) {
  .tenpura_img {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
}

.tenpura_img:last-of-type {
  margin-right: 0;
}

.top-visual {
  max-width: 100%;
  width: 100%;
  margin-bottom: 40px;
  overflow: hidden;
}

@media (max-width: 767px) {
  .top-visual {
    margin-bottom: 15px;
    max-width: 100%;
    height: 112vw;
  }
}

.top-visual img {
  max-height: 549px;
}

@media (max-width: 767px) {
  .top-visual img {
    max-height: 112vw;
  }
}

.top-introduction {
  padding: 0 40px;
  margin-bottom: 60px;
}

@media (max-width: 767px) {
  .top-introduction {
    padding: 0;
    margin-bottom: 30px;
  }
}

.top-introduction p {
  font-size: 1.0625rem;
  line-height: 2;
}

@media (max-width: 767px) {
  .top-introduction p {
    font-size: 0.75rem;
    line-height: 1.75;
  }
}

.top-features {
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 767px) {
  .top-features {
    flex-direction: column;
  }
}

.top-features article {
  width: 476px;
  margin-bottom: 50px;
  text-align: right;
}

@media (max-width: 767px) {
  .top-features article {
    width: 100%;
    margin-bottom: 30px;
  }
}

.top-features article:nth-of-type(2n) {
  margin-left: auto;
}

.top-features article h3 {
  margin: 30px 0 15px;
  color: #900;
  font-size: 1.4375rem;
  font-weight: normal;
  letter-spacing: .3em;
  text-align: left;
  line-height: 1;
}

@media (max-width: 767px) {
  .top-features article h3 {
    margin: 15px 0 10px;
    font-size: 0.9375rem;
  }
}

.top-features article p {
  margin: 0 0 5px;
  text-align: left;
  font-size: 0.9375rem;
  line-height: 1.75;
}

@media (max-width: 767px) {
  .top-features article p {
    font-size: 0.75rem;
  }
}

.top-features article a {
  font-size: 0.9375rem;
  color: #900;
  letter-spacing: .3em;
  text-decoration: underline;
}

@media (max-width: 767px) {
  .top-features article a {
    font-size: 0.8125rem;
  }
}

.top-features article a:hover {
  text-decoration: none;
}

.top-shop_visual {
  display: flex;
  margin: 25px 0 50px;
  padding: 0;
  list-style: none;
}

@media (max-width: 767px) {
  .top-shop_visual {
    flex-wrap: wrap;
    margin: 15px 0 30px;
  }
}

.top-shop_visual li {
  width: 232px;
  margin-right: auto;
}

@media (max-width: 767px) {
  .top-shop_visual li {
    width: 47%;
  }
}

@media (max-width: 767px) {
  .top-shop_visual li:nth-of-type(2) {
    margin-right: 0;
  }
}

.top-shop_visual li:last-of-type {
  margin-right: 0;
}

.top-shop_description {
  text-align: center;
  font-size: 1.40625rem;
  color: #cc9600;
  margin-bottom: 50px;
}

@media (max-width: 767px) {
  .top-shop_description {
    font-size: 1.0625rem;
    margin-bottom: 30px;
  }
  .top-shop_description br {
    display: none;
  }
}

.top-shop_btn {
  list-style: none;
  margin: 0;
  padding: 0;
}

.top-shop_btn li {
  margin-bottom: 30px;
  border: 1px solid #ccc;
}

.top-shop_btn li a {
  display: flex;
  align-items: center;
  transition: opacity .15s;
}

.top-shop_btn li a:hover {
  text-decoration: none;
  opacity: .6;
}

.top-shop_btn li a:hover img {
  opacity: 1;
}

.top-shop_btn li a ._img {
  flex: 0 0 auto;
  width: 598px;
}

@media (max-width: 767px) {
  .top-shop_btn li a ._img {
    width: 41.06667vw;
  }
}

.top-shop_btn li a ._content {
  padding-right: 15px;
}

@media (max-width: 767px) {
  .top-shop_btn li a ._content {
    padding-right: 2vw;
  }
}

.top-shop_btn li a ._content h3 {
  margin: 0;
  color: #cc9600;
  font-size: 2.1875rem;
  font-weight: normal;
  letter-spacing: .1em;
}

@media (max-width: 767px) {
  .top-shop_btn li a ._content h3 {
    font-size: 1rem;
    text-align: right;
  }
}

.top-shop_btn li a ._content p {
  margin: 0;
  color: #000;
  font-size: 0.8125rem;
  line-height: 2;
}

@media (max-width: 767px) {
  .top-shop_btn li a ._content p {
    font-size: 0.5625rem;
    line-height: 1.5;
  }
}

.top-information {
  display: flex;
  margin: 60px 0 30px;
}

@media (max-width: 767px) {
  .top-information {
    flex-wrap: wrap;
    margin: 30px 0 15px;
  }
}

.top-information div {
  flex: 0 0 auto;
}

.top-information article h3 {
  margin: 0 0 20px;
  text-align: center;
  font-size: 1.6875rem;
  font-weight: normal;
  letter-spacing: .15em;
  color: #cc9600;
}

@media (max-width: 767px) {
  .top-information article h3 {
    margin: 0 0 5px;
    font-size: 1.375rem;
  }
}

.top-information_btn {
  position: absolute;
  display: block;
  top: .7em;
  right: 0;
  width: 60px;
  padding: .5em 0;
  background-color: #c1272d;
  font-size: 0.75rem;
  font-weight: bold;
  border-radius: 4px;
  color: #fff;
  text-align: center;
  transition: opacity .15s;
}

.top-information_btn:hover {
  text-decoration: none;
  opacity: .75;
}

.top-information_btn:visited {
  color: #fff;
}

.top-information_box {
  position: relative;
  margin-right: auto;
}

@media (max-width: 767px) {
  .top-information_box {
    margin-right: 0;
    width: 100%;
  }
}

.top-news {
  width: 494px;
  margin-bottom: 30px;
}

@media (max-width: 767px) {
  .top-news {
    width: 100%;
  }
}

.top-news ul {
  list-style: none;
  margin: 0;
  padding: 0;
  border-top: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-left: 1px solid #ccc;
}

@media (max-width: 767px) {
  .top-news ul {
    border: 0;
  }
}

.top-news ul li {
  padding: 15px;
  border-bottom: 1px solid #ccc;
  font-size: 0.9375rem;
}

@media (max-width: 767px) {
  .top-news ul li {
    padding: 0 0 10px;
    margin-bottom: 10px;
  }
}

.top-news ul li span {
  display: inline-block;
  margin-right: 15px;
  color: #cc9600;
}

@media (max-width: 767px) {
  .top-news ul li span {
    display: block;
  }
}

.top-news ul li a {
  color: #000;
}

.top-media {
  display: block;
  border: 2px solid #c1272d;
  text-align: center;
  color: #c1272d;
  font-size: 1.5625rem;
  font-weight: bold;
  line-height: 180px;
  letter-spacing: .2em;
  transition: opacity .15s;
}

@media (max-width: 767px) {
  .top-media {
    margin-bottom: 15px;
    border-width: 4px;
    font-size: 1.3125rem;
    line-height: 100px;
  }
}

.top-media:hover {
  color: #c1272d;
  text-decoration: none;
  opacity: .7;
}

.top-fb {
  width: 469px;
}

.top-blog {
  margin-top: 40px;
}

.u_bg-text {
  background-color: #333 !important;
}

.u_bg-text-link {
  background-color: #c1272d !important;
}

.u_bg-text-link-hover {
  background-color: #c1272d !important;
}

.u_bg-text-link-visited {
  background-color: #c1272d !important;
}

.u_bg-white {
  background-color: #fff !important;
}

.u_bg-black {
  background-color: #000 !important;
}

.u_bg-gray {
  background-color: #ccc !important;
}

.u_bg-gold {
  background-color: #cc9600 !important;
}

.u_bg-dark-red {
  background-color: #900 !important;
}

.u_bg-red {
  background-color: #c1272d !important;
}

@media (max-width: 479px) {
  .u_bp-hide-xs {
    display: none !important;
  }
}

@media (max-width: 479px) {
  .u_bp-hide-lte-xs {
    display: none !important;
  }
}

.u_bp-hide-gte-xs {
  display: none !important;
}

@media (max-width: 479px) {
  .u_bp-show-xs-block {
    display: block !important;
  }
}

@media (max-width: 479px) {
  .u_bp-show-lte-xs-block {
    display: block !important;
  }
}

.u_bp-show-gte-xs-block {
  display: block !important;
}

@media (max-width: 479px) {
  .u_bp-show-xs-inline {
    display: inline !important;
  }
}

@media (max-width: 479px) {
  .u_bp-show-lte-xs-inline {
    display: inline !important;
  }
}

.u_bp-show-gte-xs-inline {
  display: inline !important;
}

@media (max-width: 479px) {
  .u_bp-show-xs-inline-block {
    display: inline-block !important;
  }
}

@media (max-width: 479px) {
  .u_bp-show-lte-xs-inline-block {
    display: inline-block !important;
  }
}

.u_bp-show-gte-xs-inline-block {
  display: inline-block !important;
}

@media (min-width: 480px) and (max-width: 767px) {
  .u_bp-hide-sm {
    display: none !important;
  }
}

@media (max-width: 767px) {
  .u_bp-hide-lte-sm {
    display: none !important;
  }
}

@media (min-width: 480px) {
  .u_bp-hide-gte-sm {
    display: none !important;
  }
}

@media (min-width: 480px) and (max-width: 767px) {
  .u_bp-show-sm-block {
    display: block !important;
  }
}

@media (max-width: 767px) {
  .u_bp-show-lte-sm-block {
    display: block !important;
  }
}

@media (min-width: 480px) {
  .u_bp-show-gte-sm-block {
    display: block !important;
  }
}

@media (min-width: 480px) and (max-width: 767px) {
  .u_bp-show-sm-inline {
    display: inline !important;
  }
}

@media (max-width: 767px) {
  .u_bp-show-lte-sm-inline {
    display: inline !important;
  }
}

@media (min-width: 480px) {
  .u_bp-show-gte-sm-inline {
    display: inline !important;
  }
}

@media (min-width: 480px) and (max-width: 767px) {
  .u_bp-show-sm-inline-block {
    display: inline-block !important;
  }
}

@media (max-width: 767px) {
  .u_bp-show-lte-sm-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 480px) {
  .u_bp-show-gte-sm-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .u_bp-hide-md {
    display: none !important;
  }
}

@media (max-width: 1023px) {
  .u_bp-hide-lte-md {
    display: none !important;
  }
}

@media (min-width: 768px) {
  .u_bp-hide-gte-md {
    display: none !important;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .u_bp-show-md-block {
    display: block !important;
  }
}

@media (max-width: 1023px) {
  .u_bp-show-lte-md-block {
    display: block !important;
  }
}

@media (min-width: 768px) {
  .u_bp-show-gte-md-block {
    display: block !important;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .u_bp-show-md-inline {
    display: inline !important;
  }
}

@media (max-width: 1023px) {
  .u_bp-show-lte-md-inline {
    display: inline !important;
  }
}

@media (min-width: 768px) {
  .u_bp-show-gte-md-inline {
    display: inline !important;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .u_bp-show-md-inline-block {
    display: inline-block !important;
  }
}

@media (max-width: 1023px) {
  .u_bp-show-lte-md-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 768px) {
  .u_bp-show-gte-md-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 1024px) and (max-width: 1439px) {
  .u_bp-hide-lg {
    display: none !important;
  }
}

@media (max-width: 1439px) {
  .u_bp-hide-lte-lg {
    display: none !important;
  }
}

@media (min-width: 1024px) {
  .u_bp-hide-gte-lg {
    display: none !important;
  }
}

@media (min-width: 1024px) and (max-width: 1439px) {
  .u_bp-show-lg-block {
    display: block !important;
  }
}

@media (max-width: 1439px) {
  .u_bp-show-lte-lg-block {
    display: block !important;
  }
}

@media (min-width: 1024px) {
  .u_bp-show-gte-lg-block {
    display: block !important;
  }
}

@media (min-width: 1024px) and (max-width: 1439px) {
  .u_bp-show-lg-inline {
    display: inline !important;
  }
}

@media (max-width: 1439px) {
  .u_bp-show-lte-lg-inline {
    display: inline !important;
  }
}

@media (min-width: 1024px) {
  .u_bp-show-gte-lg-inline {
    display: inline !important;
  }
}

@media (min-width: 1024px) and (max-width: 1439px) {
  .u_bp-show-lg-inline-block {
    display: inline-block !important;
  }
}

@media (max-width: 1439px) {
  .u_bp-show-lte-lg-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 1024px) {
  .u_bp-show-gte-lg-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 1440px) {
  .u_bp-hide-xl {
    display: none !important;
  }
}

.u_bp-hide-lte-xl {
  display: none !important;
}

@media (min-width: 1440px) {
  .u_bp-hide-gte-xl {
    display: none !important;
  }
}

@media (min-width: 1440px) {
  .u_bp-show-xl-block {
    display: block !important;
  }
}

.u_bp-show-lte-xl-block {
  display: block !important;
}

@media (min-width: 1440px) {
  .u_bp-show-gte-xl-block {
    display: block !important;
  }
}

@media (min-width: 1440px) {
  .u_bp-show-xl-inline {
    display: inline !important;
  }
}

.u_bp-show-lte-xl-inline {
  display: inline !important;
}

@media (min-width: 1440px) {
  .u_bp-show-gte-xl-inline {
    display: inline !important;
  }
}

@media (min-width: 1440px) {
  .u_bp-show-xl-inline-block {
    display: inline-block !important;
  }
}

.u_bp-show-lte-xl-inline-block {
  display: inline-block !important;
}

@media (min-width: 1440px) {
  .u_bp-show-gte-xl-inline-block {
    display: inline-block !important;
  }
}

.u_cf {
  overflow: hidden;
}

.u_cf-legacy::before, .u_cf-legacy::after {
  display: table;
  content: " ";
}

.u_cf-legacy::after {
  clear: both;
}

.u_dp-none {
  display: none !important;
}

.u_dp-block {
  display: block !important;
}

.u_dp-inline {
  display: inline !important;
}

.u_dp-inline-block {
  display: inline-block !important;
}

.u_flip-horizontal {
  transform: scale(-1, 1);
}

.u_flip-vertical {
  transform: scale(1, -1);
}

.u_fw-100 {
  font-weight: 100 !important;
}

.u_fw-200 {
  font-weight: 200 !important;
}

.u_fw-300 {
  font-weight: 300 !important;
}

.u_fw-400 {
  font-weight: 400 !important;
}

.u_fw-500 {
  font-weight: 500 !important;
}

.u_fw-600 {
  font-weight: 600 !important;
}

.u_fw-700 {
  font-weight: 700 !important;
}

.u_fw-800 {
  font-weight: 800 !important;
}

.u_fw-900 {
  font-weight: 900 !important;
}

.u_fw-normal {
  font-weight: normal !important;
}

.u_fw-bold {
  font-weight: bold !important;
}

.u_ls-5 {
  letter-spacing: 0.05em !important;
}

.u_ls-10 {
  letter-spacing: 0.1em !important;
}

.u_ls-15 {
  letter-spacing: 0.15em !important;
}

.u_ls-20 {
  letter-spacing: 0.2em !important;
}

.u_ls-25 {
  letter-spacing: 0.25em !important;
}

.u_ls-30 {
  letter-spacing: 0.3em !important;
}

.u_ls-35 {
  letter-spacing: 0.35em !important;
}

.u_ls-40 {
  letter-spacing: 0.4em !important;
}

.u_ls-45 {
  letter-spacing: 0.45em !important;
}

.u_ls-50 {
  letter-spacing: 0.5em !important;
}

.u_ls-minus5 {
  letter-spacing: -0.05em !important;
}

.u_ls-minus10 {
  letter-spacing: -0.1em !important;
}

.u_ls-minus15 {
  letter-spacing: -0.15em !important;
}

.u_ls-minus20 {
  letter-spacing: -0.2em !important;
}

.u_ls-minus25 {
  letter-spacing: -0.25em !important;
}

.u_ls-minus30 {
  letter-spacing: -0.3em !important;
}

.u_ls-minus35 {
  letter-spacing: -0.35em !important;
}

.u_ls-minus40 {
  letter-spacing: -0.4em !important;
}

.u_ls-minus45 {
  letter-spacing: -0.45em !important;
}

.u_ls-minus50 {
  letter-spacing: -0.5em !important;
}

.u_ls-normal {
  letter-spacing: normal !important;
}

.u_m-0 {
  margin: 0px !important;
}

.u_my-0 {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.u_mx-0 {
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.u_mt-0 {
  margin-top: 0px !important;
}

.u_mr-0 {
  margin-right: 0px !important;
}

.u_mb-0 {
  margin-bottom: 0px !important;
}

.u_ml-0 {
  margin-left: 0px !important;
}

.u_m-5 {
  margin: 5px !important;
}

.u_my-5 {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.u_mx-5 {
  margin-right: 5px !important;
  margin-left: 5px !important;
}

.u_mt-5 {
  margin-top: 5px !important;
}

.u_mr-5 {
  margin-right: 5px !important;
}

.u_mb-5 {
  margin-bottom: 5px !important;
}

.u_ml-5 {
  margin-left: 5px !important;
}

.u_m-10 {
  margin: 10px !important;
}

.u_my-10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.u_mx-10 {
  margin-right: 10px !important;
  margin-left: 10px !important;
}

.u_mt-10 {
  margin-top: 10px !important;
}

.u_mr-10 {
  margin-right: 10px !important;
}

.u_mb-10 {
  margin-bottom: 10px !important;
}

.u_ml-10 {
  margin-left: 10px !important;
}

.u_m-15 {
  margin: 15px !important;
}

.u_my-15 {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

.u_mx-15 {
  margin-right: 15px !important;
  margin-left: 15px !important;
}

.u_mt-15 {
  margin-top: 15px !important;
}

.u_mr-15 {
  margin-right: 15px !important;
}

.u_mb-15 {
  margin-bottom: 15px !important;
}

.u_ml-15 {
  margin-left: 15px !important;
}

.u_m-20 {
  margin: 20px !important;
}

.u_my-20 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.u_mx-20 {
  margin-right: 20px !important;
  margin-left: 20px !important;
}

.u_mt-20 {
  margin-top: 20px !important;
}

.u_mr-20 {
  margin-right: 20px !important;
}

.u_mb-20 {
  margin-bottom: 20px !important;
}

.u_ml-20 {
  margin-left: 20px !important;
}

.u_m-25 {
  margin: 25px !important;
}

.u_my-25 {
  margin-top: 25px !important;
  margin-bottom: 25px !important;
}

.u_mx-25 {
  margin-right: 25px !important;
  margin-left: 25px !important;
}

.u_mt-25 {
  margin-top: 25px !important;
}

.u_mr-25 {
  margin-right: 25px !important;
}

.u_mb-25 {
  margin-bottom: 25px !important;
}

.u_ml-25 {
  margin-left: 25px !important;
}

.u_m-30 {
  margin: 30px !important;
}

.u_my-30 {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

.u_mx-30 {
  margin-right: 30px !important;
  margin-left: 30px !important;
}

.u_mt-30 {
  margin-top: 30px !important;
}

.u_mr-30 {
  margin-right: 30px !important;
}

.u_mb-30 {
  margin-bottom: 30px !important;
}

.u_ml-30 {
  margin-left: 30px !important;
}

.u_m-35 {
  margin: 35px !important;
}

.u_my-35 {
  margin-top: 35px !important;
  margin-bottom: 35px !important;
}

.u_mx-35 {
  margin-right: 35px !important;
  margin-left: 35px !important;
}

.u_mt-35 {
  margin-top: 35px !important;
}

.u_mr-35 {
  margin-right: 35px !important;
}

.u_mb-35 {
  margin-bottom: 35px !important;
}

.u_ml-35 {
  margin-left: 35px !important;
}

.u_m-40 {
  margin: 40px !important;
}

.u_my-40 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}

.u_mx-40 {
  margin-right: 40px !important;
  margin-left: 40px !important;
}

.u_mt-40 {
  margin-top: 40px !important;
}

.u_mr-40 {
  margin-right: 40px !important;
}

.u_mb-40 {
  margin-bottom: 40px !important;
}

.u_ml-40 {
  margin-left: 40px !important;
}

.u_m-45 {
  margin: 45px !important;
}

.u_my-45 {
  margin-top: 45px !important;
  margin-bottom: 45px !important;
}

.u_mx-45 {
  margin-right: 45px !important;
  margin-left: 45px !important;
}

.u_mt-45 {
  margin-top: 45px !important;
}

.u_mr-45 {
  margin-right: 45px !important;
}

.u_mb-45 {
  margin-bottom: 45px !important;
}

.u_ml-45 {
  margin-left: 45px !important;
}

.u_m-50 {
  margin: 50px !important;
}

.u_my-50 {
  margin-top: 50px !important;
  margin-bottom: 50px !important;
}

.u_mx-50 {
  margin-right: 50px !important;
  margin-left: 50px !important;
}

.u_mt-50 {
  margin-top: 50px !important;
}

.u_mr-50 {
  margin-right: 50px !important;
}

.u_mb-50 {
  margin-bottom: 50px !important;
}

.u_ml-50 {
  margin-left: 50px !important;
}

.u_m-55 {
  margin: 55px !important;
}

.u_my-55 {
  margin-top: 55px !important;
  margin-bottom: 55px !important;
}

.u_mx-55 {
  margin-right: 55px !important;
  margin-left: 55px !important;
}

.u_mt-55 {
  margin-top: 55px !important;
}

.u_mr-55 {
  margin-right: 55px !important;
}

.u_mb-55 {
  margin-bottom: 55px !important;
}

.u_ml-55 {
  margin-left: 55px !important;
}

.u_m-60 {
  margin: 60px !important;
}

.u_my-60 {
  margin-top: 60px !important;
  margin-bottom: 60px !important;
}

.u_mx-60 {
  margin-right: 60px !important;
  margin-left: 60px !important;
}

.u_mt-60 {
  margin-top: 60px !important;
}

.u_mr-60 {
  margin-right: 60px !important;
}

.u_mb-60 {
  margin-bottom: 60px !important;
}

.u_ml-60 {
  margin-left: 60px !important;
}

.u_m-65 {
  margin: 65px !important;
}

.u_my-65 {
  margin-top: 65px !important;
  margin-bottom: 65px !important;
}

.u_mx-65 {
  margin-right: 65px !important;
  margin-left: 65px !important;
}

.u_mt-65 {
  margin-top: 65px !important;
}

.u_mr-65 {
  margin-right: 65px !important;
}

.u_mb-65 {
  margin-bottom: 65px !important;
}

.u_ml-65 {
  margin-left: 65px !important;
}

.u_m-70 {
  margin: 70px !important;
}

.u_my-70 {
  margin-top: 70px !important;
  margin-bottom: 70px !important;
}

.u_mx-70 {
  margin-right: 70px !important;
  margin-left: 70px !important;
}

.u_mt-70 {
  margin-top: 70px !important;
}

.u_mr-70 {
  margin-right: 70px !important;
}

.u_mb-70 {
  margin-bottom: 70px !important;
}

.u_ml-70 {
  margin-left: 70px !important;
}

.u_m-75 {
  margin: 75px !important;
}

.u_my-75 {
  margin-top: 75px !important;
  margin-bottom: 75px !important;
}

.u_mx-75 {
  margin-right: 75px !important;
  margin-left: 75px !important;
}

.u_mt-75 {
  margin-top: 75px !important;
}

.u_mr-75 {
  margin-right: 75px !important;
}

.u_mb-75 {
  margin-bottom: 75px !important;
}

.u_ml-75 {
  margin-left: 75px !important;
}

.u_m-80 {
  margin: 80px !important;
}

.u_my-80 {
  margin-top: 80px !important;
  margin-bottom: 80px !important;
}

.u_mx-80 {
  margin-right: 80px !important;
  margin-left: 80px !important;
}

.u_mt-80 {
  margin-top: 80px !important;
}

.u_mr-80 {
  margin-right: 80px !important;
}

.u_mb-80 {
  margin-bottom: 80px !important;
}

.u_ml-80 {
  margin-left: 80px !important;
}

.u_m-85 {
  margin: 85px !important;
}

.u_my-85 {
  margin-top: 85px !important;
  margin-bottom: 85px !important;
}

.u_mx-85 {
  margin-right: 85px !important;
  margin-left: 85px !important;
}

.u_mt-85 {
  margin-top: 85px !important;
}

.u_mr-85 {
  margin-right: 85px !important;
}

.u_mb-85 {
  margin-bottom: 85px !important;
}

.u_ml-85 {
  margin-left: 85px !important;
}

.u_m-90 {
  margin: 90px !important;
}

.u_my-90 {
  margin-top: 90px !important;
  margin-bottom: 90px !important;
}

.u_mx-90 {
  margin-right: 90px !important;
  margin-left: 90px !important;
}

.u_mt-90 {
  margin-top: 90px !important;
}

.u_mr-90 {
  margin-right: 90px !important;
}

.u_mb-90 {
  margin-bottom: 90px !important;
}

.u_ml-90 {
  margin-left: 90px !important;
}

.u_m-95 {
  margin: 95px !important;
}

.u_my-95 {
  margin-top: 95px !important;
  margin-bottom: 95px !important;
}

.u_mx-95 {
  margin-right: 95px !important;
  margin-left: 95px !important;
}

.u_mt-95 {
  margin-top: 95px !important;
}

.u_mr-95 {
  margin-right: 95px !important;
}

.u_mb-95 {
  margin-bottom: 95px !important;
}

.u_ml-95 {
  margin-left: 95px !important;
}

.u_m-100 {
  margin: 100px !important;
}

.u_my-100 {
  margin-top: 100px !important;
  margin-bottom: 100px !important;
}

.u_mx-100 {
  margin-right: 100px !important;
  margin-left: 100px !important;
}

.u_mt-100 {
  margin-top: 100px !important;
}

.u_mr-100 {
  margin-right: 100px !important;
}

.u_mb-100 {
  margin-bottom: 100px !important;
}

.u_ml-100 {
  margin-left: 100px !important;
}

.u_p-0 {
  padding: 0px !important;
}

.u_py-0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.u_px-0 {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.u_pt-0 {
  padding-top: 0px !important;
}

.u_pr-0 {
  padding-right: 0px !important;
}

.u_pb-0 {
  padding-bottom: 0px !important;
}

.u_pl-0 {
  padding-left: 0px !important;
}

.u_p-5 {
  padding: 5px !important;
}

.u_py-5 {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.u_px-5 {
  padding-right: 5px !important;
  padding-left: 5px !important;
}

.u_pt-5 {
  padding-top: 5px !important;
}

.u_pr-5 {
  padding-right: 5px !important;
}

.u_pb-5 {
  padding-bottom: 5px !important;
}

.u_pl-5 {
  padding-left: 5px !important;
}

.u_p-10 {
  padding: 10px !important;
}

.u_py-10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.u_px-10 {
  padding-right: 10px !important;
  padding-left: 10px !important;
}

.u_pt-10 {
  padding-top: 10px !important;
}

.u_pr-10 {
  padding-right: 10px !important;
}

.u_pb-10 {
  padding-bottom: 10px !important;
}

.u_pl-10 {
  padding-left: 10px !important;
}

.u_p-15 {
  padding: 15px !important;
}

.u_py-15 {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.u_px-15 {
  padding-right: 15px !important;
  padding-left: 15px !important;
}

.u_pt-15 {
  padding-top: 15px !important;
}

.u_pr-15 {
  padding-right: 15px !important;
}

.u_pb-15 {
  padding-bottom: 15px !important;
}

.u_pl-15 {
  padding-left: 15px !important;
}

.u_p-20 {
  padding: 20px !important;
}

.u_py-20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.u_px-20 {
  padding-right: 20px !important;
  padding-left: 20px !important;
}

.u_pt-20 {
  padding-top: 20px !important;
}

.u_pr-20 {
  padding-right: 20px !important;
}

.u_pb-20 {
  padding-bottom: 20px !important;
}

.u_pl-20 {
  padding-left: 20px !important;
}

.u_p-25 {
  padding: 25px !important;
}

.u_py-25 {
  padding-top: 25px !important;
  padding-bottom: 25px !important;
}

.u_px-25 {
  padding-right: 25px !important;
  padding-left: 25px !important;
}

.u_pt-25 {
  padding-top: 25px !important;
}

.u_pr-25 {
  padding-right: 25px !important;
}

.u_pb-25 {
  padding-bottom: 25px !important;
}

.u_pl-25 {
  padding-left: 25px !important;
}

.u_p-30 {
  padding: 30px !important;
}

.u_py-30 {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}

.u_px-30 {
  padding-right: 30px !important;
  padding-left: 30px !important;
}

.u_pt-30 {
  padding-top: 30px !important;
}

.u_pr-30 {
  padding-right: 30px !important;
}

.u_pb-30 {
  padding-bottom: 30px !important;
}

.u_pl-30 {
  padding-left: 30px !important;
}

.u_p-35 {
  padding: 35px !important;
}

.u_py-35 {
  padding-top: 35px !important;
  padding-bottom: 35px !important;
}

.u_px-35 {
  padding-right: 35px !important;
  padding-left: 35px !important;
}

.u_pt-35 {
  padding-top: 35px !important;
}

.u_pr-35 {
  padding-right: 35px !important;
}

.u_pb-35 {
  padding-bottom: 35px !important;
}

.u_pl-35 {
  padding-left: 35px !important;
}

.u_p-40 {
  padding: 40px !important;
}

.u_py-40 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}

.u_px-40 {
  padding-right: 40px !important;
  padding-left: 40px !important;
}

.u_pt-40 {
  padding-top: 40px !important;
}

.u_pr-40 {
  padding-right: 40px !important;
}

.u_pb-40 {
  padding-bottom: 40px !important;
}

.u_pl-40 {
  padding-left: 40px !important;
}

.u_p-45 {
  padding: 45px !important;
}

.u_py-45 {
  padding-top: 45px !important;
  padding-bottom: 45px !important;
}

.u_px-45 {
  padding-right: 45px !important;
  padding-left: 45px !important;
}

.u_pt-45 {
  padding-top: 45px !important;
}

.u_pr-45 {
  padding-right: 45px !important;
}

.u_pb-45 {
  padding-bottom: 45px !important;
}

.u_pl-45 {
  padding-left: 45px !important;
}

.u_p-50 {
  padding: 50px !important;
}

.u_py-50 {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}

.u_px-50 {
  padding-right: 50px !important;
  padding-left: 50px !important;
}

.u_pt-50 {
  padding-top: 50px !important;
}

.u_pr-50 {
  padding-right: 50px !important;
}

.u_pb-50 {
  padding-bottom: 50px !important;
}

.u_pl-50 {
  padding-left: 50px !important;
}

.u_p-55 {
  padding: 55px !important;
}

.u_py-55 {
  padding-top: 55px !important;
  padding-bottom: 55px !important;
}

.u_px-55 {
  padding-right: 55px !important;
  padding-left: 55px !important;
}

.u_pt-55 {
  padding-top: 55px !important;
}

.u_pr-55 {
  padding-right: 55px !important;
}

.u_pb-55 {
  padding-bottom: 55px !important;
}

.u_pl-55 {
  padding-left: 55px !important;
}

.u_p-60 {
  padding: 60px !important;
}

.u_py-60 {
  padding-top: 60px !important;
  padding-bottom: 60px !important;
}

.u_px-60 {
  padding-right: 60px !important;
  padding-left: 60px !important;
}

.u_pt-60 {
  padding-top: 60px !important;
}

.u_pr-60 {
  padding-right: 60px !important;
}

.u_pb-60 {
  padding-bottom: 60px !important;
}

.u_pl-60 {
  padding-left: 60px !important;
}

.u_p-65 {
  padding: 65px !important;
}

.u_py-65 {
  padding-top: 65px !important;
  padding-bottom: 65px !important;
}

.u_px-65 {
  padding-right: 65px !important;
  padding-left: 65px !important;
}

.u_pt-65 {
  padding-top: 65px !important;
}

.u_pr-65 {
  padding-right: 65px !important;
}

.u_pb-65 {
  padding-bottom: 65px !important;
}

.u_pl-65 {
  padding-left: 65px !important;
}

.u_p-70 {
  padding: 70px !important;
}

.u_py-70 {
  padding-top: 70px !important;
  padding-bottom: 70px !important;
}

.u_px-70 {
  padding-right: 70px !important;
  padding-left: 70px !important;
}

.u_pt-70 {
  padding-top: 70px !important;
}

.u_pr-70 {
  padding-right: 70px !important;
}

.u_pb-70 {
  padding-bottom: 70px !important;
}

.u_pl-70 {
  padding-left: 70px !important;
}

.u_p-75 {
  padding: 75px !important;
}

.u_py-75 {
  padding-top: 75px !important;
  padding-bottom: 75px !important;
}

.u_px-75 {
  padding-right: 75px !important;
  padding-left: 75px !important;
}

.u_pt-75 {
  padding-top: 75px !important;
}

.u_pr-75 {
  padding-right: 75px !important;
}

.u_pb-75 {
  padding-bottom: 75px !important;
}

.u_pl-75 {
  padding-left: 75px !important;
}

.u_p-80 {
  padding: 80px !important;
}

.u_py-80 {
  padding-top: 80px !important;
  padding-bottom: 80px !important;
}

.u_px-80 {
  padding-right: 80px !important;
  padding-left: 80px !important;
}

.u_pt-80 {
  padding-top: 80px !important;
}

.u_pr-80 {
  padding-right: 80px !important;
}

.u_pb-80 {
  padding-bottom: 80px !important;
}

.u_pl-80 {
  padding-left: 80px !important;
}

.u_p-85 {
  padding: 85px !important;
}

.u_py-85 {
  padding-top: 85px !important;
  padding-bottom: 85px !important;
}

.u_px-85 {
  padding-right: 85px !important;
  padding-left: 85px !important;
}

.u_pt-85 {
  padding-top: 85px !important;
}

.u_pr-85 {
  padding-right: 85px !important;
}

.u_pb-85 {
  padding-bottom: 85px !important;
}

.u_pl-85 {
  padding-left: 85px !important;
}

.u_p-90 {
  padding: 90px !important;
}

.u_py-90 {
  padding-top: 90px !important;
  padding-bottom: 90px !important;
}

.u_px-90 {
  padding-right: 90px !important;
  padding-left: 90px !important;
}

.u_pt-90 {
  padding-top: 90px !important;
}

.u_pr-90 {
  padding-right: 90px !important;
}

.u_pb-90 {
  padding-bottom: 90px !important;
}

.u_pl-90 {
  padding-left: 90px !important;
}

.u_p-95 {
  padding: 95px !important;
}

.u_py-95 {
  padding-top: 95px !important;
  padding-bottom: 95px !important;
}

.u_px-95 {
  padding-right: 95px !important;
  padding-left: 95px !important;
}

.u_pt-95 {
  padding-top: 95px !important;
}

.u_pr-95 {
  padding-right: 95px !important;
}

.u_pb-95 {
  padding-bottom: 95px !important;
}

.u_pl-95 {
  padding-left: 95px !important;
}

.u_p-100 {
  padding: 100px !important;
}

.u_py-100 {
  padding-top: 100px !important;
  padding-bottom: 100px !important;
}

.u_px-100 {
  padding-right: 100px !important;
  padding-left: 100px !important;
}

.u_pt-100 {
  padding-top: 100px !important;
}

.u_pr-100 {
  padding-right: 100px !important;
}

.u_pb-100 {
  padding-bottom: 100px !important;
}

.u_pl-100 {
  padding-left: 100px !important;
}

.u_pos-relative {
  position: relative !important;
}

.u_pos-absolute {
  position: absolute !important;
}

.u_post-static {
  position: static !important;
}

.u_sr {
  width: 1px !important;
  height: 1px !important;
  clip: rect(0, 0, 0, 0);
  border: 0 !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  position: absolute !important;
}

.u_t-text {
  color: #333 !important;
}

.u_t-text-link {
  color: #c1272d !important;
}

.u_t-text-link-hover {
  color: #c1272d !important;
}

.u_t-text-link-visited {
  color: #c1272d !important;
}

.u_t-white {
  color: #fff !important;
}

.u_t-black {
  color: #000 !important;
}

.u_t-gray {
  color: #ccc !important;
}

.u_t-gold {
  color: #cc9600 !important;
}

.u_t-dark-red {
  color: #900 !important;
}

.u_t-red {
  color: #c1272d !important;
}

.u_t-left {
  text-align: left !important;
}

.u_t-center {
  text-align: center !important;
}

.u_t-right {
  text-align: right !important;
}

.u_t-smaller {
  font-size: smaller !important;
}

.u_t-larger {
  font-size: larger !important;
}

.u_va-baseline {
  vertical-align: baseline !important;
}

.u_va-top {
  vertical-align: top !important;
}

.u_va-middle {
  vertical-align: middle !important;
}

.u_va-bottom {
  vertical-align: bottom !important;
}

.u_va-text-bottom {
  vertical-align: text-bottom !important;
}

.u_va-text-top {
  vertical-align: text-top !important;
}

.u_vis-visible {
  visibility: visible !important;
}

.u_vis-hidden {
  visibility: hidden !important;
}

.u_wrap-wrap {
  white-space: normal !important;
}

.u_wrap-nowrap {
  white-space: nowrap !important;
}

.u_wrap-break-all {
  word-break: break-all !important;
}
