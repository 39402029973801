@charset "UTF-8";

.about {
  &_container {
    display: flex;
    margin-bottom: 40px;
    @include mq-max("sm") {
      flex-direction: column;
    }
  }
  &_img {
    flex: 0 0 auto;
    width: 300px;
    margin-right: 30px;
    @include mq-max("sm") {
      width: 100%;
      margin: 0 0 20px 0;
    }
  }
  &_content {
    h4 {
      font-size: rem(20);
      margin-top: 0;
      color: c("gold");
      font-weight: normal;
      @include mq-max("sm") {
        font-size: rem(18);
      }
    }
    p {
      font-size: rem(14);
    }
  }
  &_map {
    iframe {
      width: 100%;
      @include mq-max("sm") {
        height: 280px;
      }
    }
  }
  .m-heading {
    margin-bottom: 40px;
    @include mq-max("sm") {
      margin-bottom: 20px;
    }
  }
}
