@charset "UTF-8";

.post {
  padding-left: 50px;
  padding-right: 50px;
  @include mq-max("sm") {
    padding-left: 0;
    padding-right: 0;
  }
  &_thumb {
    text-align: center;
  }
  &_contents {
    word-break: break-all;
    h1 {
      margin-top: 0;
      padding: 0 20px;
      background-color: c("black");
      color: c("gold");
      font-size: rem(22);
      font-weight: normal;
      line-height: 50px;
      @include mq-max("sm") {
        font-size: rem(17);
        margin-bottom: 10px;
        line-height: 35px;
      }
    }
    h2 {
      color: c("red");
      font-weight: normal;
    }
    p {
      line-height: 2;
    }
    form {
      padding: 0;
      margin: 60px 0;
      @include mq-max("sm") {
        margin: 30px 0;
      }
      label {
        display: flex;
        @include mq-max("sm") {
          flex-direction: column;
        }
        ._label {
          width: 180px;
          padding: .5em 1em .5em 0;
          text-align: right;
          @include mq-max("sm") {
            display: block;
            width: 100%;
            text-align: left;
          }
        }
        ._input {
          flex: 1 0 auto;
          input , textarea {
            width: 100%;
          }
        }
        input , textarea {
          background-color: #f2f2f2;
          border: 0;
          padding: .5em;
        }
      }
      ._footer {
        display: flex;
        flex-wrap: wrap;
        margin-left: 180px;
        @include mq-max("sm") {
          margin-left: 0;
        }
        .g-recaptcha {
          width: 100%;
        }
        ._button {
          width: 100%;
          padding: .5em;
          background-color: c("black");
          color: c("white");
          font-size: rem(20);
        }
        ._back {
          width: 45%;
          padding: .5em;
          margin: 0 auto;
          background-color: #999;
          color: c("white");
          font-size: rem(20);
        }
        ._submit {
          width: 45%;
          margin: 0 auto;
          padding: .5em;
          background-color: c("black");
          color: c("white");
          font-size: rem(20);
        }
      }
    }
    table {
      border-top: 1px solid c("gray");
      border-left: 1px solid c("gray");
      th , td {
        padding: .2em .5em;
        border-right: 1px solid c("gray");
        border-bottom: 1px solid c("gray");
      }
      thead {
        th {
          background-color: #eee;
        }
      }
    }
    img {
      max-width: 100%;
    }
    div {
      max-width: 100%;
    }
    blockquote {
      padding: 1em;
      background-color: #eee;
      border: 1px solid c("gray");
      @include mq-max("sm") {
        margin: 1em;
      }
      cite {
        display: block;
        font-weight: bold;
        color: #888;
        text-align: right;
      }
    }
    a {
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
    p , pre {
      word-break: break-all;
      overflow-x: auto;
    }
  }
}
