@charset "UTF-8";

.p-main {
  padding-top: 60px;
  @include mq-max("sm") {
    padding-top: 40px;
  }
  &._is-top {
    padding-top: 0;
  }
}
