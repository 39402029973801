@charset "UTF-8";

.top {
  &-visual {
    max-width: 100%;
    width: 100%;
    margin-bottom: 40px;
    overflow: hidden;
    @include mq-max("sm") {
      margin-bottom: 15px;
      max-width: 100%;
      height: vw(840);
    }
    img {
      max-height: 549px;
      @include mq-max("sm") {
        max-height: vw(840);
      }
    }
  }
  &-introduction {
    padding: 0 40px;
    margin-bottom: 60px;
    @include mq-max("sm") {
      padding: 0;
      margin-bottom: 30px;
    }
    p {
      font-size: rem(17);
      line-height: 2;
      @include mq-max("sm") {
        font-size: rem(12);
        line-height: 1.75;
      }
    }
  }
  &-features {
    display: flex;
    flex-wrap: wrap;
    @include mq-max("sm") {
      flex-direction: column;
    }
    article {
      width: 476px;
      margin-bottom: 50px;
      text-align: right;
      @include mq-max("sm") {
        width: 100%;
        margin-bottom: 30px;
      }
      &:nth-of-type(2n) {
        margin-left: auto;
      }
      h3 {
        margin: 30px 0 15px;
        color: c("dark-red");
        font-size: rem(23);
        font-weight: normal;
        letter-spacing: .3em;
        text-align: left;
        line-height: 1;
        @include mq-max("sm") {
          margin: 15px 0 10px;
          font-size: rem(15);
        }
      }
      p {
        margin: 0 0 5px;
        text-align: left;
        font-size: rem(15);
        line-height: 1.75;
        @include mq-max("sm") {
          font-size: rem(12);
        }
      }
      a {
        font-size: rem(15);
        color: c("dark-red");
        letter-spacing: .3em;
        text-decoration: underline;
        @include mq-max("sm") {
          font-size: rem(13);
        }
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
  &-shop {
    &_visual {
      display: flex;
      margin: 25px 0 50px;
      padding: 0;
      list-style: none;
      @include mq-max("sm") {
        flex-wrap: wrap;
        margin: 15px 0 30px;
      }
      li {
        width: 232px;
        margin-right: auto;
        @include mq-max("sm") {
          width: 47%;
        }
        &:nth-of-type(2) {
          @include mq-max("sm") {
            margin-right: 0;
          }
        }
        &:last-of-type {
          margin-right: 0;
        }
      }
    }
    &_description {
      text-align: center;
      font-size: rem(22.5);
      color: c("gold");
      margin-bottom: 50px;
      @include mq-max("sm") {
        font-size: rem(17);
        margin-bottom: 30px;
        br {
          display: none;
        }
      }
    }
    &_btn {
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        margin-bottom: 30px;
        border: 1px solid c("gray");
        a {
          display: flex;
          align-items: center;
          transition: opacity .15s;
          &:hover {
            text-decoration: none;
            opacity: .6;
            img {
              opacity: 1;
            }
          }
          ._img {
            flex: 0 0 auto;
            width: 598px;
            @include mq-max("sm") {
              width: vw(308);
            }
          }
          ._content {
            padding-right: 15px;
            @include mq-max("sm") {
              padding-right: vw(15);
            }
            h3 {
              margin: 0;
              color: c("gold");
              font-size: rem(35);
              font-weight: normal;
              letter-spacing: .1em;
              @include mq-max("sm") {
                font-size: rem(16);
                text-align: right;
              }
            }
            p {
              margin: 0;
              color: c("black");
              font-size: rem(13);
              line-height: 2;
              @include mq-max("sm") {
                font-size: rem(9);
                line-height: 1.5;
              }
            }
          }
        }
      }
    }
  }
  &-information {
    display: flex;
    margin: 60px 0 30px;
    @include mq-max("sm") {
      flex-wrap: wrap;
      margin: 30px 0 15px;
    }
    div {
      flex: 0 0 auto;
    }
    article {
      h3 {
        margin: 0 0 20px;
        text-align: center;
        font-size: rem(27);
        font-weight: normal;
        letter-spacing: .15em;
        color: c("gold");
        @include mq-max("sm") {
          margin: 0 0 5px;
          font-size: rem(22);
        }
      }
    }
    &_btn {
      position: absolute;
      display: block;
      top: .7em;
      right: 0;
      width: 60px;
      padding: .5em 0;
      background-color: c("red");
      font-size: rem(12);
      font-weight: bold;
      border-radius: 4px;
      color: c("white");
      text-align: center;
      transition: opacity .15s;
      &:hover {
        text-decoration: none;
        opacity: .75;
      }
      &:visited {
        color: c("white");
      }
    }
    &_box {
      position: relative;
      margin-right: auto;
      @include mq-max("sm") {
        margin-right: 0;
        width: 100%;
      }
    }
  }
  &-news {
    width: 494px;
    margin-bottom: 30px;
    @include mq-max("sm") {
      width: 100%;
    }
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      border-top: 1px solid c("gray");
      border-right: 1px solid c("gray");
      border-left: 1px solid c("gray");
      @include mq-max("sm") {
        border: 0;
      }
      li {
        padding: 15px;
        border-bottom: 1px solid c("gray");
        font-size: rem(15);
        @include mq-max("sm") {
          padding: 0 0 10px;
          margin-bottom: 10px;
        }
        span {
          display: inline-block;
          margin-right: 15px;
          color: c("gold");
          @include mq-max("sm") {
            display: block;
          }
        }
        a {
          color: c("black");
        }
      }
    }
  }
  &-media {
    display: block;
    border: 2px solid c("red");
    text-align: center;
    color: c("red");
    font-size: rem(25);
    font-weight: bold;
    line-height: 180px;
    letter-spacing: .2em;
    transition: opacity .15s;
    @include mq-max("sm") {
      margin-bottom: 15px;
      border-width: 4px;
      font-size: rem(21);
      line-height: 100px;
    }
    &:hover {
      color: c("red");
      text-decoration: none;
      opacity: .7;
    }
  }
  &-fb {
    width: 469px;
  }
  &-blog {
    margin-top: 40px;
  }
}
