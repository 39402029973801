@charset "UTF-8";

.p-footer {
  position: relative;
  padding: 30px 0 20px;
  margin-top: 80px;
  background-color: c("black");
  color: c("white");
  @include mq-max("sm") {
    margin-top: 50px;
    padding: 15px 0;
  }
  &_container {
    display: flex;
    width: 1000px;
    margin: 0 auto 60px;
    @include mq-max("sm") {
      flex-wrap: wrap;
      width: auto;
      margin: 0 vw(30) 30px;
    }
  }
  &_information {
    margin-right: auto;
    h3 {
      margin: 0 0 20px;
      width: 134px;
      @include mq-max("sm") {
        width: vw(214);
        margin-bottom: 10px;
      }
    }
    p {
      font-size: rem(14);
      @include mq-max("sm") {
        font-size: rem(10);
      }
    }
  }
  &_nav {
    ul {
      display: flex;
      list-style: none;
      margin: 0;
      padding: 0;
      @include mq-max("sm") {
        display: none;
      }
      li {
        margin-left: 24px;
        a {
          color: c("white");
          font-size: rem(14);
          font-weight: bold;
          span {
            color: c("red");
          }
        }
      }
    }
  }
  &_copy {
    text-align: center;
    color: #666;
    font-size: rem(14);
    @include mq-max("sm") {
      font-size: rem(7);
      color: c("white");
    }
  }
  &_btn {
    display: block;
    width: 109px;
    margin-top: 25px;
    margin-left: auto;
    @include mq-max("sm") {
      position: absolute;
      width: vw(145);
      top: vw(-70);
      right: vw(20);
      margin: 0;
    }
  }
}
