@charset "UTF-8";

.p-blog {
  &_body {
    border-top: 1px solid c("gray");
  }
  &_article {
    display: flex;
    padding-bottom: 20px;
    margin: 20px;
    border-bottom: 1px solid c("gray");
    @include mq-max("sm") {
      flex-direction: column;
      margin: 20px 0;
    }
  }
  &_contents {
    flex: 1 1 auto;
  }
  &_content {
    p {
      word-break: break-all;
    }
  }
  &_thumb {
    width: 360px;
    flex: 0 0 auto;
    margin-left: 30px;
    @include mq-max("sm") {
      width: 100%;
      margin: 10px 0 0;
    }
  }
  &_title {
    margin: 0;
    color: c("gold");
    font-size: rem(24);
    font-weight: normal;
  }
  &_more {
    display: block;
    width: 100px;
    margin-left: auto;
    font-size: rem(14);
  }
  &_category {
    flex: 0 0 auto;
    display: flex;
    flex-wrap: wrap;
    margin: 12px 0 20px;
    padding: 0;
    &._is-archive {
      margin: 12px 0 0;
    }
    a {
      margin-right: 5px;
      display: block;
      background-color: #999;
      padding: .1em .5em;
      border-radius: 4px;
      color: c("white");
      font-size: rem(12);
      font-weight: bold;
      transition: all .15s;
      &:hover {
        text-decoration: none;
        background-color: c("red");
      }
    }
  }
}
