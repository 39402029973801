@charset "UTF-8";

.m-contact {
  display: flex;
  align-items: center;
  padding: 10px 0;
  background-color: c("red");
  @include mq-max("sm") {
    flex-direction: column;
    padding: 12px;
  }
  &_title {
    width: 214px;
    margin-left: 15px;
    @include mq-max("sm") {
      display: none;
    }
  }
  &_info {
    margin-left: 15px;
    color: c("white");
    letter-spacing: .1em;
    @include mq-max("sm") {
      text-align: center;
      margin: 0;
    }
    h4 {
      margin: 0;
      font-size: rem(28);
      line-height: 1;
      @include mq-max("sm") {
        font-size: rem(14);
      }
    }
  }
  &_address {
    margin: 10px 0 0;
    font-size: rem(15);
    @include mq-max("sm") {
      font-size: rem(9);
      margin-top: 5px;
    }
  }
  &_tel {
    margin: 0;
    font-family: $_font-century;
    font-size: rem(28);
    @include mq-max("sm") {
      font-size: rem(14);
      margin-bottom: 5px;
    }
  }
  &_btn {
    margin-left: auto;
    margin-right: 35px;
    @include mq-max("sm") {
      margin: 0;
    }
    a {
      display: block;
      width: 380px;
      height: 36px;
      background-color: c("white");
      border-radius: 18px;
      line-height: 36px;
      color: #ed1c24;
      text-align: center;
      font-weight: bold;
      font-size: rem(21);
      letter-spacing: .2em;
      @include mq-max("sm") {
        width: vw(380);
        font-size: rem(10);
        line-height: 18px;
        height: 18px;
        border-radius: 9px;
      }
    }
  }
}
