@charset "UTF-8";

.m-heading {
  margin-top: 0;
  padding: 0 20px;
  background-color: c("black");
  color: c("gold");
  font-size: rem(22);
  font-weight: normal;
  line-height: 50px;
  @include mq-max("sm") {
    font-size: rem(17);
    margin-bottom: 10px;
    line-height: 35px;
  }
  &::before {
    display: inline-block;
    content: "";
    width: 45px;
    height: 42px;
    margin-right: 8px;
    background: url("../images/icon-mark.png") center no-repeat;
    background-size: contain;
    vertical-align: middle;
    @include mq-max("sm") {
      width: 25px;
      height: 22px;
      margin-right: 5px;
    }
  }
  &._white {
    padding: 0;
    background: none;
    color: c("gold");
    font-size: rem(29);
    @include mq-max("sm") {
      font-size: rem(25);
    }
  }
  &._no-mark {
    &::before {
      display: none;
    }
  }
  &-sub {
    font-size: rem(26);
    font-weight: normal;
    color: c("red");
    margin-bottom: .5em;
    @include mq-max("sm") {
      font-size: rem(16);
    }
  }
}
