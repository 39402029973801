@charset "UTF-8";

.m-title {
  margin-top: 0;
  margin-bottom: 1.5em;
  position: relative;
  text-align: center;
  color: c("gold");
  font-size: rem(36);
  font-weight: normal;
  @include mq-max("sm") {
    font-size: rem(30);
  }
  &::after {
    position: absolute;
    content: "";
    display: block;
    bottom: -10px;
    left: 50%;
    width: 42px;
    height: 8px;
    background-color: c("gold");
    opacity: .3;
    transform: translateX(-50%);
  }
}
