@charset "UTF-8";

.m-table {
  table {
    width: 100%;
    border-top: 1px solid c("gray");
    border-left: 1px solid c("gray");
    @include mq-max("sm") {
      font-size: rem(14);
    }
    tr {
      @include mq-max("sm") {
        display: block;
      }
    }
    th , td {
      padding: 8px 12px;
      border-right: 1px solid c("gray");
      border-bottom: 1px solid c("gray");
      @include mq-max("sm") {
        display: block;
      }
    }
    th {
      font-weight: normal;
      background-color: #eee;
    }
  }
}
