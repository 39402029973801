@charset "UTF-8";

.l-container {
  width: 1000px;
  margin: 0 auto;
  @include mq-max("sm") {
    width: auto;
    margin: 0 vw(30);
  }
}
