@charset "UTF-8";

.news {
  width: 800px;
  @include mq-max("sm") {
    width: auto;
  }
  &_container {
    margin-bottom: 60px;
    border-top: 1px solid c("gray");
    border-right: 1px solid c("gray");
    border-left: 1px solid c("gray");
    @include mq-max("sm") {
      margin-bottom: 30px;
    }
  }
  &_article {
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid c("gray");
    padding: .5em 0;
    @include mq-max("sm") {
      flex-direction: column;
    }
  }
  &_date {
    flex: 0 0 auto;
    width: 120px;
    font-size: rem(16);
    color: c("gold");
    padding: 0 1em;
  }
  &_title {
    margin: 0;
    font-weight: normal;
    font-size: rem(16);
    padding: 0 1em;
    a {
      color: c("black");
      &:hover {
        color: c("red");
      }
    }
  }
  &_category {
    flex: 0 0 auto;
    margin: 0 0 20px;
    max-width: 100%;
    &._is-archive {
      margin: 0 0 0 20px;
    }
    ul {
      display: flex;
      flex-wrap: wrap;
      margin: 2px 0 0;
      padding: 0;
      list-style: none;
      li {
        margin-right: 5px;
        a {
          display: block;
          background-color: #999;
          padding: .1em .5em;
          border-radius: 4px;
          color: c("white");
          font-size: rem(12);
          font-weight: bold;
          transition: all .15s;
          &:hover {
            text-decoration: none;
            background-color: c("red");
          }
        }
      }
    }
  }
}
