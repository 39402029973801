@charset "UTF-8";

.u_sr {
  width: 1px !important;
  height: 1px !important;
  clip: rect(0, 0, 0, 0);
  border: 0 !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  position: absolute !important;
}
